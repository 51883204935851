<template>
  <div class="exam-root-2">
    <div class="exam-top">
      <img
        src="../../assets/arrow_circle_back.png"
        style="cursor: pointer; width: 36px; height: auto"
        @click="goBack"
      />
      <!-- <img
        src="../../assets/loginLogo.png"
        style="height: 30px; margin-left: 30px"
      /> -->
    </div>
    <div class="exam-content">
      <div class="transcriptContainer" style="margin-bottom: 10px">
        <!-- 背景图 -->
        <img src="../../assets/参赛证明(2).jpg" class="model_p" />
        <!-- 姓名 -->
        <div class="name">
          {{
            upfirstName(item.student_info.student_givenName_pinyin) +
            " " +
            upfirstName(item.student_info.student_lastName_pinyin) +
            " " +
            item.student_info.student_lastName +
            " " +
            item.student_info.student_givenName
          }}
        </div>
        <!-- 信息 -->
        <div class="info">
          <div>
            <span class="tag">邮箱: </span>
            <span class="content">{{ item.student_info.student_email }}</span> |
            <span class="tag">性别: </span>
            <span class="content">{{
              item.student_info.student_gender == "男" ? "男" : "女"
            }}</span>
            |
            <span class="tag">预计毕业年份: </span>
            <span class="content">{{
              item.student_info.student_graduation
            }}</span>
          </div>
          <div style="margin-top: 6px">
            <span class="tag">学校: </span>
            <span class="content">{{ item.account_info.account_name }}</span>
          </div>
        </div>
        <!-- 团队成绩 -->
        <div class="score">
          <div v-for="score in item.score_info" :key="score._id.$id">
            <div v-if="score.score_type == 'team'" style="margin-bottom: 20px">
              <div class="title">
                {{ score.application_event + " - " + score.mode }}
              </div>
              <div style="margin-top: 5px">
                Score:<span>{{ score.total_score }}</span
                >/{{ score.full_score }}
                <span style="margin-left: 10px"></span>Percentile: Top
                <span>{{ score.percentile }}</span>
              </div>

              <div style="margin-top: 5px">
                # of Competitors: {{ score.competitor_count }}
              </div>
            </div>
          </div>
        </div>
        <!-- 个人成绩 -->
        <div class="score1">
          <div v-for="score in item.score_info" :key="score._id.$id">
            <div
              v-if="score.score_type == 'personal'"
              style="margin-bottom: 20px"
            >
              <div class="title">
                {{ score.application_event + " - " + '客观笔试' }}
              </div>
              <div style="margin-top: 5px">
                分数:<span>{{ score.total_score }}</span
                >/{{ score.full_score }}
                <!-- <span style="margin-left: 10px"></span>百分位:前
                <span>{{ score.percentile }}</span> -->
              </div>
              <!-- <div style="margin-top: 5px">
                # of Competitors：{{ score.competitor_count }}
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { findMyTranscript } from "../../api/index";
import { getUserId, getProjectCode } from "../../utils/store";
import "../../assets/common/font.css";

export default {
  data() {
    return {
      item: {},
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      findMyTranscript(getUserId(), getProjectCode()).then((res) => {
        loading.close();
        this.item = res.data.data;
      });
    },
    handleEventName(event) {
      if (event == undefined) {
        return "";
      }
      let event_array = event.split(" ");
      console.log(111, event, event_array);
      return event_array[0];
    },

    goBack() {
      this.$router.go(-1);
    },
    upfirstName(name) {
      let transferName = name.toLowerCase();
      return transferName.charAt(0).toUpperCase() + transferName.slice(1);
    },
    rank(number) {
      if (number === 1) {
        return "st";
      } else if (number === 2) {
        return "nd";
      } else if (number === 3) {
        return "rd";
      } else {
        return "th";
      }
    },
    printPersonal() {
      let url = this.$router.resolve({
        path: "/blank",
      });
      let newWindow = window.open(url.href, "_blank"); //打印窗口要换成页面的url
      let obj = document.getElementById("cerP");

      let docStr = obj.innerHTML;

      newWindow.document.write(docStr);

      newWindow.document.close();

      newWindow.print();

      newWindow.close();
    },
  },
};
</script>

<style lang="less" scoped>
@font-face {
  font-family: "aleo-bold";
  src: url("../../assets/fonts/aleo-bold.ttf");
}
@font-face {
  font-family: "Aleo-Bold-2";
  src: url("../../assets/fonts/Aleo-Bold-2.otf");
}
@font-face {
  font-family: "Aleo-Italic-4";
  src: url("../../assets/fonts/Aleo-Italic-4.otf");
}
@font-face {
  font-family: "Aleo-Light-5";
  src: url("../../assets/fonts/Aleo-Light-5.otf");
}
@font-face {
  font-family: "Aleo-Regular-7";
  src: url("../../assets/fonts/Aleo-Regular-7.otf");
}
.el-dialog {
  width: 320mm;
}
.exam-content {
  width: 100%;
  flex: 1;
  // background-image: url("../../assets/exam-content-bg.png");
  background-repeat: no-repeat;
  background-color: #4E8AFC;
  background-size: cover;
  box-sizing: border-box;
  position: relative;
  padding: 30px 9vw;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
}

.awardTitle {
  font-weight: bold;
  text-align: center;
  color: #0f429e;
  font-size: 60px;
  margin-top: 30px;
  font-family: Montserrat-SemiBold;
}
.awardTitle1 {
  font-weight: bold;
  text-align: left;
  color: #0036a0;
  font-size: 30px;
  margin-top: 60px;
  margin-left: 80px;
  font-family: Montserrat-SemiBold;
}
.awardLine {
  background-color: #00359f;
  margin-left: 80px;
  margin-right: 80px;
  height: 1px;
  margin-top: 10px;
  font-family: Montserrat-SemiBold;
}
.scoreLab1 {
  margin-top: 60px;
  margin-right: 10px;
  height: 40px;
  //   background-color: rebeccapurple;
  text-align: right;
  font-size: 30px;
  color: #fd5900;
  float: right;
  font-family: Montserrat-SemiBold;
}

.scoreLab2 {
  margin-top: 65px;
  margin-right: 80px;
  height: 40px;
  //   background-color: rebeccapurple;
  text-align: right;
  color: #0f429e;
  font-size: 25px;
  float: right;
  font-family: Montserrat-SemiBold;
}
.awardSubTitle {
  text-align: left;
  color: #2d50b0;
  font-size: 15px;
  margin-top: 20px;
  margin-left: 80px;
  font-family: Montserrat-SemiBold;
}
.returnStyle {
  position: absolute;
  // display: flex;
  // margin-bottom: 10px;
  // align-items: center;
  bottom: 40px;
  margin-left: 45%;
  font-weight: 600;
  width: 140px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  // align-content: center;
  // align-items: center;
  border-radius: 15px;
  background-color: #2d50b0;
  color: white;
  font-family: Montserrat-SemiBold;
}
.box {
  display: flex;
  justify-content: left;
  margin-left: 80px;
  margin-right: 80px;
  margin-top: 20px;
  // height: 250px;
  // background-color: #FD5900;
  flex-wrap: wrap;
  align-content: flex-start;
  align-items: left;
  align-content: left;
  text-align: left;
  float: left;
}
.child {
  // width: 50px;
  // background-color: aqua;
  height: 30px;
  padding-left: 10px;
}
.infoStyle {
  display: flex;
  border: 1px solid #2d50b0;
  border-radius: 16px;
  color: #2d50b0;
  margin-left: 20px;
  margin-top: -25px;
  font-family: Montserrat-SemiBold;
}
.infoImg {
  width: 14px;
  height: 14px;
}
.exam-top {
  height: 120px;
  // background: #00348b;
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: cover;
  // background: #00348b;
  background-image: url("../../assets/am-top-bg.png");
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 9vw;
}

.exam-root-2 {
  width: 100%;
  height: 100vh;
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.transcriptContainer {
  position: relative;
  margin-bottom: -4px;
  .model_p {
    width: 210mm;
    height: 295mm;
    z-index: 0;
  }
  .name {
    position: absolute;
    top: 326px;
    left: 38px;
    font-size: 30px;
  }
  .info {
    position: absolute;
    top: 384px;
    left: 38px;
    font-size: 15px;
    .tag {
      font-family: "Aleo-Bold-2";
    }
    .content {
      font-family: "Aleo-Regular-7";
    }
  }
  .score {
    position: absolute;
    top: 500px;
    width: 320px;

    left: 55px;
    font-size: 12px;
    font-family: "Aleo-Regular-7";
    span {
      font-family: "Aleo-Bold-2";
      font-size: 14px;
    }
    .title {
      font-family: "aleo-bold";
    }
  }
  .score1 {
    position: absolute;
    top: 500px;
    width: 320px;
    left: 434px;
    font-size: 15px;
    font-family: "Aleo-Regular-7";
    span {
      font-family: "Aleo-Bold-2";
      font-size: 17px;
    }
    .title {
      font-family: "aleo-bold";
    }
  }
}
</style>
